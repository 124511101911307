var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { staticClass: "card", attrs: { flat: "" } }, [
    _c("figure", [
      _c("img", {
        staticClass: "profile-img",
        attrs: { src: _vm.imagemPerfil, alt: "imagem de perfil" },
      }),
    ]),
    _c("div", { staticClass: "perfil" }, [
      _c("div", { staticClass: "nome" }, [
        _c("span", [
          _vm._v(
            " Olá, " + _vm._s(_vm.user.name || "Joilson da SIlva Lima") + ". "
          ),
        ]),
      ]),
      _c("div", { staticClass: "email w-300" }, [
        _c("span", [
          _vm._v(" " + _vm._s(_vm.user.email || "nome@email.com") + " "),
        ]),
      ]),
      _c("div", { staticClass: "cadastro w-300" }, [
        _c("span", [
          _vm._v(" cadastrado desde " + _vm._s(_vm.dataCriacao) + " "),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }