<template>
  <v-card flat class="card" v-if="user">
    <div class="title">
      <span>Detalhes do seu plano</span>
    </div>
    <div class="textos-plano">
      <div class="nome">
        <span
          ><span class="strong">Plano</span> Kristal
          <span :style="{ color: dadosPlanoAtual.color }" class="strong-2">
            {{ dadosPlanoAtual.title }}
          </span>
        </span>
      </div>
      <div class="descricao">
        <span>Descrição do produto. Se é mensal, anual, e etc.</span>
      </div>
      <div class="empresa">
        <span class="strong">Kristal Connect</span>
      </div>
      <div class="valor">
        <span class="valor-pagamento strong">{{ dadosPlanoAtual.preco }}</span>
        <span class="tipo-pagamento" v-if="user.planType > 0"
          >Pagamento à vista - feito com cartão de crédito</span
        >
      </div>
    </div>

    <div class="botoes">
      <div class="cancelar" @click="goToCancel" v-if="user.planType > 0">
        <span>Cancelar</span>
      </div>
      <div class="alterar" @click="goEscolherOutroPlano">
        <span>Alterar</span>
      </div>
    </div>

    <div class="compra">
      <span> compra em 21/12/2021 </span>
    </div>
  </v-card>
</template>
<script>
import ApiService from "../../../../services/ApiService";
import TiposPlanos from "../../../../services/TiposPlanos";

export default {
  data() {
    return {
      user: undefined,
      apiService: new ApiService(),
      planos: new TiposPlanos(),
      userVuex: undefined,
    };
  },
  props: {
    dadosCartao: { tyope: Object, default: undefined },
  },
  methods: {
    async init() {
      try {
        let req = await this.apiService.get(`user/${this.userVuex.id}`);
        this.user = req.content;
      } catch (error) {
        this.$toast.error("Error para buscar usuário");
      }
    },
    goToCancel() {
      this.$router.push({ name: "cancelarPlano" });
    },
    goEscolherOutroPlano() {
      let accountType = ''

      if(this.user.userType == 0){
         accountType = {type: 'investidor', id: this.user.userType}
      }
       if(this.user.userType == 1){
         accountType = {type: 'acessor', id: this.user.userType}
      }
      this.$store.dispatch('PUT_PROFILE_TYPE', accountType)
      this.$router.push({ name: "selecaoAssinatura" });
    },
  },
  created() {
    this.userVuex = this.$store.getters.$userLogged;
    this.init();
  },
  computed: {
    dadosPlanoAtual() {
      if (this.user) {
        return this.planos.SelecionarPlano(this.user.planType);
      }
      return "";
    },
  },
};
</script>
<style  scoped>
.card {
  border: 1px solid #dadada;
  border-radius: 7px;
  padding: 2rem;
  position: relative;
}
.card > div {
  margin: 1rem 0;
}
.title {
  font-weight: 700;
  margin: 0.5rem 0;
}
.strong {
  font-weight: 600;
  color: black;
}
.strong-2 {
  font-weight: 500;
}
.empresa span {
  font-size: 0.8em;
}
.textos-plano {
  color: #5f6368;
}
.textos-plano > div {
  margin: 0.3rem 0;
}
.textos-plano .nome {
  font-weight: 300;
}
.textos-plano .descricao {
  font-size: 0.8em;
}
.textos-plano .valor span {
  display: block;
}
.textos-plano .valor .valor-pagamento {
  font-size: 1.4em;
}
.textos-plano .valor .tipo-pagamento {
  font-size: 0.8em;
  font-weight: 600;
  color: #b9b9b9;
}
.botoes {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.botoes > div {
  margin: 0.3rem;
  padding: 0.1rem 1rem;
  border-radius: 20px;
  cursor: pointer;
}
.cancelar {
  border: 1px solid var(--principal-azul);
  color: var(--principal-azul);
}
.alterar {
  background: var(--principal-azul);
  color: white;
}
.compra {
  position: absolute;
  top: 15px;
  right: 20px;
}
.compra span {
  font-weight: 300;
  font-size: 0.8em;
}

@media screen and (max-width: 590px) {
  .botoes {
    position: static;
  }
}
</style>