<template>
  <v-card flat class="card">
    <figure>
      <img :src="imagemPerfil" alt="imagem de perfil" class="profile-img" />
    </figure>
    <div class="perfil">
      <div class="nome">
        <span> Olá, {{ user.name || "Joilson da SIlva Lima" }}. </span>
      </div>

      <div class="email w-300">
        <span> {{ user.email || "nome@email.com" }} </span>
      </div>

      <div class="cadastro w-300">
        <span> cadastrado desde {{ dataCriacao }} </span>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      user: undefined,
    };
  },
  created() {
    this.user = this.$store.getters.$userLogged;
  },
  watch: {
    userLoggedVuex: {
      handler(nv, ov) {
        if (nv) {
          this.user = nv;
        }
      },
      deep: true,
    },
  },
  computed: {
    dataCriacao() {
      let data = moment(this.user.created_at).format("DD/MM/YYYY");
      return data;
    },
    imagemPerfil() {
      return this.user.profileImage != null
        ? `data:image/png;base64,${this.user.profileImage}`
        : require('@/assets/empty.png');
    },
    ...mapGetters({
      userLoggedVuex: "$userLogged",
    }),
  },
};
</script>

<style  scoped>
.card {
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  border: 1px solid #dadada;
  border-radius: 7px;
}
figure {
  height: 100px;
  width: 100px;
  overflow: hidden;
}
figure .profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.perfil > div {
  margin: 0.3rem 0;
}
.perfil .nome span {
  font-weight: 700;
  font-size: 1.2em;
}
.perfil .w-300 span {
  font-weight: 300;
}

@media screen and (max-width: 480px) {
  .card {
    justify-content: center;
    text-align: center;
  }
}
</style>