var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user
    ? _c("v-card", { staticClass: "card", attrs: { flat: "" } }, [
        _c("div", { staticClass: "title" }, [
          _c("span", [_vm._v("Detalhes do seu plano")]),
        ]),
        _c("div", { staticClass: "textos-plano" }, [
          _c("div", { staticClass: "nome" }, [
            _c("span", [
              _c("span", { staticClass: "strong" }, [_vm._v("Plano")]),
              _vm._v(" Kristal "),
              _c(
                "span",
                {
                  staticClass: "strong-2",
                  style: { color: _vm.dadosPlanoAtual.color },
                },
                [_vm._v(" " + _vm._s(_vm.dadosPlanoAtual.title) + " ")]
              ),
            ]),
          ]),
          _c("div", { staticClass: "descricao" }, [
            _c("span", [
              _vm._v("Descrição do produto. Se é mensal, anual, e etc."),
            ]),
          ]),
          _c("div", { staticClass: "empresa" }, [
            _c("span", { staticClass: "strong" }, [_vm._v("Kristal Connect")]),
          ]),
          _c("div", { staticClass: "valor" }, [
            _c("span", { staticClass: "valor-pagamento strong" }, [
              _vm._v(_vm._s(_vm.dadosPlanoAtual.preco)),
            ]),
            _vm.user.planType > 0
              ? _c("span", { staticClass: "tipo-pagamento" }, [
                  _vm._v("Pagamento à vista - feito com cartão de crédito"),
                ])
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "botoes" }, [
          _vm.user.planType > 0
            ? _c(
                "div",
                { staticClass: "cancelar", on: { click: _vm.goToCancel } },
                [_c("span", [_vm._v("Cancelar")])]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "alterar", on: { click: _vm.goEscolherOutroPlano } },
            [_c("span", [_vm._v("Alterar")])]
          ),
        ]),
        _c("div", { staticClass: "compra" }, [
          _c("span", [_vm._v(" compra em 21/12/2021 ")]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }