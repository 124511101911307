<template>
  <v-card flat class="formulario-card">
    <div class="title">
      <span> Cadastro </span>
    </div>

    <div class="conteudo" v-if="user != undefined">
      <v-form class="formulario" ref="form" v-model="valid" lazy-validation>
        <div class="nome">
          <span> Nome completo</span>

          <v-text-field
            v-model="user.name"
            placeholder="Joilson da Silva Lima"
            :rules="[(v) => !!v || 'Por favor, insira o seu nome']"
            color="secondary"
            required
            dense
            outlined
            rounded
            class="input"
          />
        </div>

        <div class="email">
          <span>E-mail</span>
          <v-text-field
            v-model="user.email"
            placeholder="nome@email.com"
            :rules="[(v) => !!v || 'Por favor, insira o seu e-mail']"
            color="secondary"
            required
            dense
            outlined
            rounded
            class="input"
          />
        </div>

        <div class="documento-celular-container">
          <div class="select-document">
            <div class="select">
              <span>Documento</span>
              <v-select
                v-model="documentType"
                :items="items"
                item-text="name"
                :menu-props="{ bottom: true, offsetY: true }"
                placeholder="Selecione"
                color="secondary"
                required
                outlined
                rounded
                dense
                class="select"
              ></v-select>
            </div>
            <div class="field">
              <v-text-field
                v-if="documentType == 'CPF'"
                v-model="user.document"
                placeholder="000.000.00-00"
                v-mask="['###.###.###-##']"
                :rules="[
                  (v) => !!v || 'Por favor, insira seu documento',
                  rules.documentMinCpf,
                ]"
                color="secondary"
                required
                outlined
                dense
                rounded
                class="input"
              />
              <v-text-field
                v-if="documentType == 'CNPJ'"
                v-model="user.document"
                v-mask="['##.###.###/####-##']"
                placeholder="00.000.000/0000-00"
                :rules="[
                  (v) => !!v || 'Por favor, insira seu documento',
                  rules.documentMinCNPJ,
                ]"
                color="secondary"
                required
                outlined
                dense
                rounded
                class="input"
              />
            </div>
          </div>
          <div class="celular">
            <span>Celular</span>

            <v-text-field
              v-model="user.phoneNumber"
              placeholder="(00) 0 0000-0000"
              v-mask="['(##)####-####', '(##)#####-####']"
              :rules="[(v) => !!v || 'Por favor, insira seu celular']"
              color="secondary"
              required
              outlined
              rounded
              dense
              class="input"
            />
          </div>
        </div>

        <div class="senha">
          <span class="btn-alterar-senha" @click="goAlterarSenha">
            Trocar senha
          </span>
        </div>
      </v-form>

      <div class="img">
        <figure>
          <img :src="imgProfile" alt="imagem de perfil" class="profile-img" />
        </figure>

        <label v-if="!changeImagemPerfil">
          <input
            type="file"
            name="fileImage"
            accept="image/png"
            id="input-file"
            @change="pegarImagem"
          />
          Trocar imagem de perfil
        </label>

        <label v-if="changeImagemPerfil" @click="cancelChangeImagemPerfil">
          Cancelar
        </label>
      </div>
    </div>

    <div class="btn">
      <v-btn
        @click="atualizarPerfil"
        color="primary"
        :loading="loading"
        class="botao-atualizar"
      >
        atualizar
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import ApiService from "../../../../services/ApiService";
import Utils from "../../../../Utils/ValidateCpnjCpf";

export default {
  data() {
    return {
      apiService: new ApiService(),
      items: ["CPF", "CNPJ"],
      loading: false,
      valid: true,
      userVuex: undefined,
      user: undefined,
      imgProfile: undefined,
      documentType: undefined,
      changeImagemPerfil: false,
      rules: {
        documentMinCpf: (value) => this.validarCpf(value) || "CPF inválido",
        documentMinCNPJ: (value) => this.validarCnpj(value) || "CNPJ inválido",
      },
    };
  },
  methods: {
    goAlterarSenha() {
      this.$router.push({ name: "alterarSenhaPerfil" });
    },
    validate() {
      return this.$refs.form.validate();
    },
    validarCpf(value) {
      if (value) {
        return Utils.validarCpf(value);
      }
    },
    validarCnpj(value) {
      if(value){
        return Utils.validarCNPJ(value);
      }
    },
    objetoEhVazio(e) {
      return Object.keys(e).length === 0;
    },
    async atualizarPerfil() {
      if (this.validate()) {
        this.user.id = this.user.id;
        this.user.profileImage = this.imgProfile;

        try {
          this.loading = true;
          this.changeImagemPerfil = false;
          let req = await this.apiService.post("user/editProfile", this.user);
          this.$toast.success(
            `${req.message}` || "Perfil atualizado com sucesso"
          );
          this.init();
          this.loading = false;
        } catch (error) {
          this.$toast.error("Erro ao atualizar perfil");
          this.loading = false;
        }
      }
    },
    pegarImagem(e) {
      let file = e.target.files[0];
      let reader = new FileReader();

      if (file && file.type.match("image.png")) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          // this.user.profileImage = reader.result;
          this.imgProfile = reader.result;
          this.changeImagemPerfil = true;
        };
      }
    },
    cancelChangeImagemPerfil() {
      this.changeImagemPerfil = false;
      this.imgProfile = `data:image/png;base64,${this.user.profileImage}`;
    },
    async init() {
      try {
        let req = await this.apiService.get(`user/${this.userVuex.id}`);
        this.user = req.content;

        this.imgProfile =
          this.user.profileImage != null
            ? `data:image/png;base64,${this.user.profileImage}`
            : require('@/assets/empty.png');
        if (this.user.document) {
          this.user.document
            .replaceAll(".", "")
            .replaceAll("/", "")
            .replaceAll("-", "").length == 11
            ? this.alterarParaCPF()
            : this.user.document.length == 14
            ? this.alterarParaCNPJ()
            : "";
        } else {
          this.alterarParaCPF();
        }

        let userParsed = JSON.stringify(this.user);
        this.$store.commit("SET_LOGGED_USER", userParsed);
        this.$store.commit("SET_TOKEN_USER_SELECTED", this.user && this.user.userId || '');
        this.$store.commit("SET_USER_SELECTED", userParsed);

      } catch (error) {
        this.$toast.error("Error para buscar usuário");
      }
    },
    alterarParaCPF() {
      this.documentType = "CPF";
    },
    alterarParaCNPJ() {
      this.documentType = "CNPJ";
    },
  },
  created() {
    this.userVuex = this.$store.getters.$userLogged;

    //this.documentType = this.userVuex.document.length > 14 ? 'CNPJ' : 'CPF';
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.formulario-card {
  border: 1px solid #dadada;
  border-radius: 7px;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  align-items: flex-end;
}
.title span {
  font-weight: 800;
  font-size: 1.2em;
}
.conteudo {
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 1rem;
  width: 100%;
  align-items: flex-end;
}
.formulario-card .formulario,
.img {
  margin: 0.5rem;
}
.formulario {
  flex: 2 2 350px;
  width: 100%;
}
.formulario > div {
  width: 100%;
}
.documento-celular-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.documento-celular-container > div {
  flex: 1 1 auto;
}
.select-document {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 1rem;
}
.select-document > div {
  flex: 1 1 auto;
}
.img {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.img figure {
  width: 200px;
  height: 200px;
  overflow: hidden;
}
.img figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.img label {
  margin: 0.5rem 0;
  font-size: 0.8em;
  color: var(--principal-azul);
  cursor: pointer;
  border-bottom: 1px solid var(--principal-azul);
}

.senha {
  position: relative;
}
.btn-alterar-senha {
  position: absolute;
  top: -5px;
  right: 10px;
  font-weight: 300;
  font-size: 0.8em;
  color: var(--principal-azul);
  border-bottom: 1px solid var(--principal-azul);
  cursor: pointer;
}
#input-file {
  display: none;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem;
}
.botao-atualizar {
  border-radius: 20px;
}
</style>