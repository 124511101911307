var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cadastro" },
    [
      _c("CardUsuario", { staticClass: "item" }),
      _c("FormularioCadastro", { staticClass: "item" }),
      _c("DetalhesPlano", {
        staticClass: "item",
        attrs: { dadosCartao: _vm.dadosCartao },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }