export default class InformacoesSobrePlanos {

    SelecionarPlano = (value) => {
        switch (value) {
            case 0:
                return { title: 'Iniciante', preco: 'Gratuito', colorFiltered: 'invert(25%) sepia(0%) saturate(0%) hue-rotate(192deg) brightness(104%) contrast(88%)', color: '#494949', data: this.vantagensPorPlano(value) }
                break;
            case 1:
                return { title: 'Padãro', preco: 'R$ 10,00', colorFiltered: 'invert(53%) sepia(99%) saturate(1245%) hue-rotate(190deg) brightness(98%) contrast(94%)', color: '#579af2', data: this.vantagensPorPlano(value) }
                break;
            case 2:
                return { title: 'Sênior', preco: 'R$ 15,00', colorFiltered: 'invert(27%) sepia(65%) saturate(563%) hue-rotate(338deg) brightness(98%) contrast(95%)', color: '#f3a03c', data: this.vantagensPorPlano(value) }
                break;
            case 3:
                return { title: 'Expert', preco: 'R$ 30,00', colorFiltered: 'invert(27%) sepia(65%) saturate(563%) hue-rotate(338deg) brightness(98%) contrast(95%)', color: '#f3a03c', data: this.vantagensPorPlano(value) }
                break;
            case 4:
                return { title: 'Profissional', preco: 'R$ 50,00', colorFiltered: 'invert(10%) sepia(57%) saturate(580%) hue-rotate(21deg) brightness(102%) contrast(83%)', color: '#b6e522', data: this.vantagensPorPlano(value) }
                break;
        }
    }

    vantagensPorPlano(e) {
        switch (e) {
            case 0:
                return ['Dashboard', 'Tela de rentabilidade', 'Tela de carteira/movimento', 'Tela de Liquidez', '90 produtos no geral', '5 Ações', '5 Fundos de Ações', '10 Títulos Públicos', '10 LCA, LCI', '10 Fundos de Curto Prazo, Fundos de Renda Fixa, Fundos Referenciados', '10 CDBs', '5 Debêntures', '5 Fundos Multimercados, Fundos Imobiliários, Fundos Cambiais']
                break;
            case 1:
                return ['Dashboard', 'Tela de rentabilidade', 'Tela de carteira/movimento', 'Tela de Liquidez', '90 produtos no geral', '5 Ações', '5 Fundos de Ações', '10 Títulos Públicos', '10 LCA, LCI', '10 Fundos de Curto Prazo, Fundos de Renda Fixa, Fundos Referenciados', '10 CDBs', '5 Debêntures', '5 Fundos Multimercados, Fundos Imobiliários, Fundos Cambiais']
                break;
            case 2:
                return ['Dashboard', 'Tela de rentabilidade', 'Tela de carteira/movimento', 'Tela de Liquidez', '180 produtos no geral', '10 Ações', '10 Fundos de Ações', '20 Títulos Públicos', '20 LCA, LCI', '20 Fundos de Curto Prazo, Fundos de Renda Fixa, Fundos Referenciados', '20 CDBs', '10 Debêntures', '10 Fundos Multimercados, Fundos Imobiliários, Fundos Cambiais']
                break;
            case 3:
                return ['Dashboard', 'Tela de rentabilidade', 'Tela de carteira/movimento', 'Tela de Liquidez', '480 produtos no geral', '40 Ações', '40 Fundos de Ações', '40 Títulos Públicos', '40 LCA, LCI', '40 Fundos de Curto Prazo, Fundos de Renda Fixa, Fundos Referenciados', '40 CDBs', '40 Debêntures', '40 Fundos Multimercados, Fundos Imobiliários, Fundos Cambiais']
                break;
            case 4:
                return ['Dashboard', 'Tela de rentabilidade', 'Tela de carteira/movimento', 'Tela de Liquidez', '480 produtos no geral', 'Ilimitado Ações', 'Ilimitado Fundos de Ações', 'Ilimitado Títulos Públicos', 'Ilimitado LCA, LCI', 'Ilimitado Fundos de Curto Prazo, Fundos de Renda Fixa, Fundos Referenciados', 'Ilimitado CDBs', 'Ilimitado Debêntures', 'Ilimitado Fundos Multimercados, Fundos Imobiliários, Fundos Cambiais']
                break;
        }

    }
}