<template>
  <div class="cadastro">
    <CardUsuario class="item" />
    <FormularioCadastro class="item" />
    <DetalhesPlano :dadosCartao="dadosCartao" class="item" />
  </div>
</template>

<script>
import ApiService from '../../../../services/ApiService'
import CardUsuario from "../components/CardUsuario.vue";
import FormularioCadastro from "../components/FormularioCadastro.vue";
import DetalhesPlano from "../components/DetalhesDoPlano.vue";
export default {
  components: {
    CardUsuario,
    FormularioCadastro,
    DetalhesPlano,
  },
  data(){
    return{
      apiService: new ApiService(),
      userVuex: undefined,
      dadosCartao: undefined
    }
  },
  methods:{
    async initCheckoutInfo(){
      try {
        let req = await this.apiService.get(`checkout/list/${this.userVuex.id}`)
        this.dadosCartao = req.content[0]
      } catch (error) {
        this.$toast.error('Ops, erro ao buscar informações do cartão de crédito')
      }
    }
  },
  created(){
    this.userVuex = this.$store.getters.$userLogged;
    //this.initCheckoutInfo();
  }
};
</script>

<style scoped >
.item {
  margin: 1.5rem 0;
}
</style>